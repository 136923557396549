//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  name: "About",
  data() {
    return {
      uid: "",
      show2: false,
      showbuy: false,
      tid: 0,
      items: [],
      conflux: null,
      zhuzaozhe: "",
      no: "",
      currbizhi: "",
      thumb: "",
      currtitle: "",
      curruid: "",
      price: "",
    };
  },
  mounted() {
    window.setMybalance = function (val) {};
    window.setMyearned = function (val) {};
    window.setMyprofit = function (val) {};
    window.setMyart = function (val) {};
    window.setMycoinname = function (val) {};
    window.setStakingbalance = function (val) {};
    this.screenWidth = 600; //document.body.clientWidth; // 屏幕宽
    this.init();
  },
  methods: {
    async init() {
      this.$nextTick(function () {
        if (document.body.clientWidth >= 768) {
          this.$router.replace({ path: "/sell" });
        }
        setTimeout(() => {
          document.getElementById("sm").className =
            "van-tabbar-item van-tabbar-item--active";
           document.getElementById("hb").className =
          "van-tabbar-item";
          document.getElementById("wd").className =
          "van-tabbar-item";
        }, 300);
        this.$axios.defaults.headers.common.Authorization = localStorage.getItem(
          "_address"
        );
        this.uid = localStorage.getItem("nft_acc_id")
          ? localStorage.getItem("nft_acc_id")
          : "";

        this.$axios.get(this.$store.state.api + "/nft/sell").then((response) => {
          this.items = response.data;
        });
      });
    },
    showbuyy(price, tid, uid, tit, img, picpath, bizhi, zhuzaozhe) {
      if (uid === this.uid) {
        return;
      }

      this.showbuy = true;

      this.thumb =
        "http://www.artii.top/nftpic/" +
        picpath +
        "/" +
        img.replace("/home/xvx/www/", "");
      this.currtitle = tit;
      this.price = price;
      this.tid = tid;
      this.curruid = uid;
      this.currbizhi = bizhi;
      try {
        this.zhuzaozhe =
          zhuzaozhe.substring(0, 6) + "..." + zhuzaozhe.substring(45, zhuzaozhe.lenght);
      } catch (error) {}
    },
    closes() {
      this.zhuzaozhe = "";
      this.showbuy = false;
    },
    // 购买产品
    async buyy() {
      const price = this.price;
      let tid = this.tid;
      const uid = this.uid; //  收购者

      let accounts = null;
      let _address = '';
      try {
        accounts = await window.conflux.enable();
      } catch (error) {}
      // 新版本手机
      if(window.isArrayFn(accounts)) {
        _address = accounts[0];
      } else {
        const accounts2 = await window.conflux.send({method: 'cfx_accounts'});
        _address = accounts2.result[0];
      }
      window._address = _address;
      

      localStorage.setItem("_address", _address);

      document.getElementById("accname").innerHTML =
        '<div class="qb_btn">' +
        _address.substring(0, 6) +
        "..." +
        _address.substring(45, _address.lenght) +
        "</div>";

      tid = +tid;
      console.log(uid);

      if (this.curruid === uid) {
        return;
      }

      this.showbuy = false;
      this.show2 = true;
      try {
        let collateral = await window.SHOP.buySellerTicket(tid).estimateGasAndCollateral({
          from: window._address,
          value: +this.$drip.fromCFX(price),
        });

        const buy = await window.SHOP.buySellerTicket(tid)
          .sendTransaction({
            gas: collateral.gasLimit,
            storageLimit: collateral.storageCollateralized,
            value: +this.$drip.fromCFX(price),
            from: window._address,
          })
          .executed();
        //console.log(buy);

        // 设置购买
        this.$axios
          .put(
            this.$store.state.api + "/nft/buy",
            this.$qs.stringify({
              id: tid, // 作品ID
              uid: uid, // 收购者
              sid: this.curruid, // 卖家
            })
          )
          .then(async (response) => {
            this.$router.push({ path: "/my" });
          });

        this.showbuy = false;
        this.show2 = false;
      } catch (error) {
        console.log(error);
        this.showbuy = false;
        this.show2 = false;
      }
    },
  },
};
